@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lora:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swa");
body {
	font-family: "Inter", sans-serif;
}
h1 {
	font-family: "Lora", serif;
}
h2 {
	font-family: "Lora", serif;
}

html {
    scroll-behavior: smooth;
}

option {
	color: black;
}

img {
  pointer-events: none;
}
::-moz-selection { /* Code for Firefox */
  color: white;
  background: #131417;
}

::selection {
  color: white;
  background: #131417;
}

::marker {
	color: white;
}